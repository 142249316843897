// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
    .footer-style {
        font-size: 15px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/pages/footer/Footer.css"],"names":[],"mappings":"AAAA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":["@media (max-width: 768px) {\r\n    .footer-style {\r\n        font-size: 15px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
