// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.education {
    display: flex;
    flex-direction: column;
    width: 70%;
    background-color: rgba(31, 32, 26, 0.4);
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px #000;
    margin-top: 20px;
    gap: 20px;
}

.education p {
    font-size: 20px;
    line-height: 1.2;
}

.education h3 {
    color: #a0632e;
}

/* styles.css */
.hovered-div {
    background-color: rgba(31, 32, 26, 0.3);
    transition: background-color 0.3s ease;
}

.hovered-div:hover {
    background-color: rgba(31, 32, 26, 0.5);
}

.courses {
    font-size: 28px;
}

.courses:hover {
    color: #a0632e;
    transition: color 1s;
}

@media (max-width: 768px) {
    .education {
        width: 90%;
    }

    .education h3 {
        font-size: 15px;
    }

    .education p {
        font-size: 12px;
    }

    .education h2 {
        font-size: 20px;
    }

    .education h4 {
        font-size: 15px;
    }

    .education h5 {
        font-size: 13px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/pages/education/Education.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,uCAAuC;IACvC,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA,eAAe;AACf;IACI,uCAAuC;IACvC,sCAAsC;AAC1C;;AAEA;IACI,uCAAuC;AAC3C;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI;QACI,UAAU;IACd;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".education {\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 70%;\r\n    background-color: rgba(31, 32, 26, 0.4);\r\n    margin: 0 auto;\r\n    padding: 20px;\r\n    border-radius: 10px;\r\n    box-shadow: 0 0 10px #000;\r\n    margin-top: 20px;\r\n    gap: 20px;\r\n}\r\n\r\n.education p {\r\n    font-size: 20px;\r\n    line-height: 1.2;\r\n}\r\n\r\n.education h3 {\r\n    color: #a0632e;\r\n}\r\n\r\n/* styles.css */\r\n.hovered-div {\r\n    background-color: rgba(31, 32, 26, 0.3);\r\n    transition: background-color 0.3s ease;\r\n}\r\n\r\n.hovered-div:hover {\r\n    background-color: rgba(31, 32, 26, 0.5);\r\n}\r\n\r\n.courses {\r\n    font-size: 28px;\r\n}\r\n\r\n.courses:hover {\r\n    color: #a0632e;\r\n    transition: color 1s;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n    .education {\r\n        width: 90%;\r\n    }\r\n\r\n    .education h3 {\r\n        font-size: 15px;\r\n    }\r\n\r\n    .education p {\r\n        font-size: 12px;\r\n    }\r\n\r\n    .education h2 {\r\n        font-size: 20px;\r\n    }\r\n\r\n    .education h4 {\r\n        font-size: 15px;\r\n    }\r\n\r\n    .education h5 {\r\n        font-size: 13px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
