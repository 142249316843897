// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

h1 {
    padding: 10px 0 10px 0;
    font-size: 3rem;
}

h2 {
    padding: 5px 0 5px 0;
    font-size: 2.3rem;
}

h3 {
    padding: 5px 0 5px 0;
    font-size: 2rem;
    color: #a0632e;
}

h4 {
    padding: 5px 0 5px 0;
    font-size: 1.5rem;
}

h5 {
    padding: 5px 0 5px 0;
    font-size: 1.3rem;
}

p {
    padding: 5px 0 5px 0;
    line-height: 1.2;
}

.main-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 5%;
    margin-top: 2%;
    color: #cccccc;
    min-height: 80vh;
}

.summary {
    width: 55%;
    font-size: 20px;
}

.main-content-contact {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 10px;
    padding-top: 10px;
}

@media (max-width: 768px) {

    h1 {
        font-size: 1.7rem;
    }

    h3 {
        font-size: 1.2rem;
    }

    .summary {
        width: 90%;
        font-size: 12px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Pages.css"],"names":[],"mappings":"AAAA;;;;;;IAMI,SAAS;AACb;;AAEA;IACI,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,oBAAoB;IACpB,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,oBAAoB;IACpB,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;IACpB,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,UAAU;IACV,cAAc;IACd,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,SAAS;IACT,iBAAiB;AACrB;;AAEA;;IAEI;QACI,iBAAiB;IACrB;;IAEA;QACI,iBAAiB;IACrB;;IAEA;QACI,UAAU;QACV,eAAe;IACnB;AACJ","sourcesContent":["h1,\r\nh2,\r\nh3,\r\nh4,\r\nh5,\r\nh6 {\r\n    margin: 0;\r\n}\r\n\r\nh1 {\r\n    padding: 10px 0 10px 0;\r\n    font-size: 3rem;\r\n}\r\n\r\nh2 {\r\n    padding: 5px 0 5px 0;\r\n    font-size: 2.3rem;\r\n}\r\n\r\nh3 {\r\n    padding: 5px 0 5px 0;\r\n    font-size: 2rem;\r\n    color: #a0632e;\r\n}\r\n\r\nh4 {\r\n    padding: 5px 0 5px 0;\r\n    font-size: 1.5rem;\r\n}\r\n\r\nh5 {\r\n    padding: 5px 0 5px 0;\r\n    font-size: 1.3rem;\r\n}\r\n\r\np {\r\n    padding: 5px 0 5px 0;\r\n    line-height: 1.2;\r\n}\r\n\r\n.main-content {\r\n    display: flex;\r\n    justify-content: space-around;\r\n    align-items: center;\r\n    margin: 5%;\r\n    margin-top: 2%;\r\n    color: #cccccc;\r\n    min-height: 80vh;\r\n}\r\n\r\n.summary {\r\n    width: 55%;\r\n    font-size: 20px;\r\n}\r\n\r\n.main-content-contact {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-around;\r\n    gap: 10px;\r\n    padding-top: 10px;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n\r\n    h1 {\r\n        font-size: 1.7rem;\r\n    }\r\n\r\n    h3 {\r\n        font-size: 1.2rem;\r\n    }\r\n\r\n    .summary {\r\n        width: 90%;\r\n        font-size: 12px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
