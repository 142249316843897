// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a,
li,
span {
    color: #cccccc;
    font-weight: normal;
}

span:hover {
    color: #a0632e;
    transition: color 1s;
}

.contact-address {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 14px;
}

.contact-page {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    gap: 50px;

}

.linkedin-size {
    font-size: 37px;
}

.contact-h4 {
    font-size: 2rem;
}


@media (max-width: 768px) {

    .contact-address {
        font-size: 12px;
    }

    .linkedin-size {
        font-size: 20px;
    }

    .contact-h4 {
        font-size: 1rem;
    }

    .contact-page {
        min-height: 81vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 50px;
    }

}`, "",{"version":3,"sources":["webpack://./src/components/pages/contact/Contact.css"],"names":[],"mappings":"AAAA;;;IAGI,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,SAAS;;AAEb;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;;AAGA;;IAEI;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,gBAAgB;QAChB,aAAa;QACb,sBAAsB;QACtB,uBAAuB;QACvB,SAAS;IACb;;AAEJ","sourcesContent":["a,\r\nli,\r\nspan {\r\n    color: #cccccc;\r\n    font-weight: normal;\r\n}\r\n\r\nspan:hover {\r\n    color: #a0632e;\r\n    transition: color 1s;\r\n}\r\n\r\n.contact-address {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    gap: 14px;\r\n}\r\n\r\n.contact-page {\r\n    min-height: 90vh;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    font-size: 22px;\r\n    gap: 50px;\r\n\r\n}\r\n\r\n.linkedin-size {\r\n    font-size: 37px;\r\n}\r\n\r\n.contact-h4 {\r\n    font-size: 2rem;\r\n}\r\n\r\n\r\n@media (max-width: 768px) {\r\n\r\n    .contact-address {\r\n        font-size: 12px;\r\n    }\r\n\r\n    .linkedin-size {\r\n        font-size: 20px;\r\n    }\r\n\r\n    .contact-h4 {\r\n        font-size: 1rem;\r\n    }\r\n\r\n    .contact-page {\r\n        min-height: 81vh;\r\n        display: flex;\r\n        flex-direction: column;\r\n        justify-content: center;\r\n        gap: 50px;\r\n    }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
