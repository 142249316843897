// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
    .carousel-media {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/pages/carousel/Carousel.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":["@media (max-width: 768px) {\r\n    .carousel-media {\r\n        display: none;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
